// 新增/编辑
const addOrUpdateInfoUrl = `/gateway/hc-edu/liveStreaming/addOrUpdateInfo`;
// 删除
const deleteByIdUrl = `/gateway/hc-edu/liveStreaming/deleteById`;
// 获取列表
const getListUrl = `/gateway/hc-edu/liveStreaming/getList`;
// 获取详情
const getDetailsUrl = `/gateway/hc-edu/liveStreaming/getDetails`;
// -------------------------------------------------------------------
// 点赞
const addPraiseURL = `/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `/gateway/hc-neighbour/topic/getTopicReplyList`;
export {
  addOrUpdateInfoUrl,
  deleteByIdUrl,
  getListUrl,
  getDetailsUrl,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
};
